import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LogoClean from "../common/LogoClean"

export default function Staff({ personal }) {
  const { personer } = personal
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className="bg-primary py-8 relative">
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        {/* Expand/Collapse Button */}
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center justify-center gap-2 mx-auto transition-all"
        >
          <span className="text-lg font-medium">Kontakta oss</span>
          <div className="w-5">
            <ChevronDown
              className={`transition-transform duration-200 ${
                isExpanded ? "rotate-180" : ""
              }`}
            />
          </div>
        </button>

        {/* Staff Grid */}
        {isExpanded && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8 py-8 mt-4">
            {personer.map((person, i) => (
              <PersonCard key={i} person={person} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

function PersonCard({ person }) {
  const { namn, titel, telefonnummer, epost, bild, extra } = person

  return (
    <div className="flex flex-col bg-white rounded-md  overflow-hidden">
      <div className="overflow-hidden bg-secondary aspect-[9/14]">
        {bild?.localFile?.childImageSharp ? (
          <GatsbyImage
            image={getImage(bild.localFile.childImageSharp)}
            alt={namn}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <div className="w-20 text-third">
              <LogoClean />
            </div>
          </div>
        )}
      </div>
      <div className="p-4 space-y-3">
        <div>
          <h3 className="font-medium text-xl mb-1">{namn}</h3>
          {titel && <p className="text-sm text-gray-600">{titel}</p>}
          {extra && <p className="text-sm italic text-gray-600">{extra}</p>}
        </div>
        <div className="space-y-2">
          <a
            href={`mailto:${epost}`}
            className="flex items-center gap-2 text-sm hover:underline text-gray-700"
          >
            <svg
              className="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
              <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
            </svg>
            {epost}
          </a>
          {telefonnummer && (
            <a
              href={`tel:${telefonnummer}`}
              className="flex items-center gap-2 text-sm hover:underline text-gray-700"
            >
              <svg
                className="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                  clipRule="evenodd"
                />
              </svg>
              {telefonnummer}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

const ChevronDown = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
    >
      <path
        fillRule="evenodd"
        d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

import React from "react"

export default function LogoClean() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Lager 1"
      viewBox="0 0 716.68 165.02"
    >
      <g data-name="Teckning 11">
        <path
          d="M49.76 58.6a11.64 11.64 0 012.47-1.52 27.71 27.71 0 002.48-1.29q14.37-6.59 26.64-6.58a39.1 39.1 0 0111.32 1.64 23.33 23.33 0 019.33 5.29 26.31 26.31 0 016.25 9.39 37.13 37.13 0 012.36 14v79.62a5.69 5.69 0 001.64 4.23c1.1 1.1 2.29 1.37 4.23 1.64H67.37c2.59-.49 3.17-.54 4.35-1.64a5.54 5.54 0 001.76-4.23V72.7q0-10.1-3.8-13.75a12.31 12.31 0 00-8.78-3.63 21.33 21.33 0 00-11.14 3.28"
          className="cls-1"
        ></path>
        <path
          d="M.12 55.79q14.39-6.59 26.64-6.58a39.1 39.1 0 0111.32 1.64 23.41 23.41 0 019.32 5.29 26.42 26.42 0 016.24 9.39 36.74 36.74 0 012.36 14v79.62a5.7 5.7 0 001.65 4.23c1.09 1.1 2.23 1.36 4.22 1.64H12.78c2.36-.4 3.18-.54 4.35-1.64a5.54 5.54 0 001.76-4.23V72.7q0-10.1-3.79-13.75a12.35 12.35 0 00-8.78-3.63c-3.8 0-7 .93-6.2.47M103.23 58.6a11.64 11.64 0 012.47-1.52 27.71 27.71 0 002.48-1.29q14.38-6.59 26.64-6.58a39.1 39.1 0 0111.32 1.64 23.38 23.38 0 019.31 5.29 26.22 26.22 0 016.25 9.39 37.13 37.13 0 012.36 14v79.62a5.7 5.7 0 001.65 4.23 6.05 6.05 0 004.22 1.64h-49.09a7.07 7.07 0 004.35-1.64 5.54 5.54 0 001.76-4.23V72.7q0-10.1-3.79-13.75a12.35 12.35 0 00-8.78-3.63 21.36 21.36 0 00-11.15 3.28M238 154.55a41.7 41.7 0 01-23.48 9.63 39.86 39.86 0 01-14.1-1.05 28.84 28.84 0 01-11.42-5.88 27 27 0 01-7.4-10.21 27.59 27.59 0 01-1.64-14.1 26 26 0 014-12 36 36 0 018-8.57 46.4 46.4 0 0110.57-6.11 126.91 126.91 0 0112-4.34 96.15 96.15 0 0015.5-6.23 35.21 35.21 0 008-5.52 65.6 65.6 0 01-7.24 6.7 41.81 41.81 0 00-7.23 7.51 38.43 38.43 0 00-5.49 11q-2.22 6.7-2.22 17.5 0 8.93 2.34 14.21a18.49 18.49 0 005.72 7.75 10.92 10.92 0 007.24 2.24 12.14 12.14 0 006.88-2.59"
          className="cls-1"
        ></path>
        <path
          d="M277.16 146.8v11.51a5.69 5.69 0 001.64 4.23 6.14 6.14 0 004.23 1.64h-26.18q-9.57 0-13.19-4.7t-3.62-16V75.63A109.73 109.73 0 00238.38 57c-.82-4.83-3.4-7.66-6.44-8.34a49.64 49.64 0 0117.57 2.47 48.1 48.1 0 0114.29 6.77 35.46 35.46 0 019.72 10.22 23.24 23.24 0 013.64 12.44zM225.15 48.86a14.41 14.41 0 00-8.24 3.14 11 11 0 00-3.91 5.78 5.8 5.8 0 001.4 5.52c1.41 1.49 2.75 2.86 4 4.11a18.71 18.71 0 014 5.87 18.37 18.37 0 011.41 7.28 18.56 18.56 0 11-37.11.86v-.86a23.18 23.18 0 012.93-11.27 32.3 32.3 0 018.08-9.63 46.31 46.31 0 0112.18-7 58.86 58.86 0 0115.23-3.75M338 158.54a5.69 5.69 0 001.64 4.23c1.1 1.1 2.2 1.35 4.23 1.65H295a6.06 6.06 0 004.22-1.65 5.7 5.7 0 001.65-4.23v-104a5.69 5.69 0 00-1.65-4.22c-1.09-1.1-2.22-1.32-4.22-1.65h26.17q9.34 0 13.07 4.7t3.74 16zM338.93 58a11.69 11.69 0 012.47-1.53 23.32 23.32 0 002.48-1.29q14.39-6.57 26.64-6.58a38.77 38.77 0 0111.32 1.65 23.21 23.21 0 019.32 5.28 26.42 26.42 0 016.24 9.39 37.13 37.13 0 012.36 14v79.62a5.7 5.7 0 001.65 4.23 5.91 5.91 0 004.22 1.65h-49.09c2-.29 3.18-.55 4.35-1.65a5.54 5.54 0 001.76-4.23V72.11q0-10.1-3.79-13.74a12.3 12.3 0 00-8.78-3.64A21.35 21.35 0 00338.93 58M417.38 106.4A68.58 68.58 0 01421 83.85a53 53 0 0110.54-18.32 50.6 50.6 0 0116.89-12.33 53 53 0 0122.37-4.58h1.2c-7.46 1.15-10.78 3.92-13.47 10.34s-4 17.22-4 32.41v30.3q0 22.79 4 32.41T472 164.42h-1.2a52.92 52.92 0 01-22.38-4.58 49.93 49.93 0 01-16.87-12.45A54.31 54.31 0 01421 129a68.59 68.59 0 01-3.63-22.55M477.6 164.18a16.27 16.27 0 008.25-3.29 10.4 10.4 0 003.87-5.63 6 6 0 00-1.29-5.52 99.842 99.842 0 00-3.88-4.11 18.32 18.32 0 01-5.63-13.39 18.79 18.79 0 0118.67-18.56 17.66 17.66 0 0113 5.52 17.91 17.91 0 015.49 13 23.88 23.88 0 01-2.93 11.51 32.33 32.33 0 01-8.09 9.63 45.87 45.87 0 01-12.18 7 58.38 58.38 0 01-15.23 3.76"
          className="cls-1"
        ></path>
        <path
          d="M476.42 48.86h.47a49.68 49.68 0 0120.79 6.57 51.78 51.78 0 0115.15 13.62 32.61 32.61 0 016.23 13.28 28 28 0 01-.12 12.21 18.8 18.8 0 01-5.05 9 12.2 12.2 0 01-8.81 3.53H469c4.48-.76 13.05-.51 16.56-6.11 2.53-4 3.73-9.87 3.73-17.38a76.53 76.53 0 00-.93-12A65.39 65.39 0 00485.75 61a29.23 29.23 0 00-4.08-8.1 9.75 9.75 0 00-5.25-4M576.86 61.54v97a5.69 5.69 0 001.64 4.23 6.79 6.79 0 004.23 1.65h-48.85a6.45 6.45 0 004.22-1.65 6.15 6.15 0 001.65-4.23V5.87A5.65 5.65 0 00534.33 0h25.73q9.33 0 13.06 4.7t3.74 16zM594 57.08a51.76 51.76 0 018.69-4.94 42.85 42.85 0 0110.1-3 29.08 29.08 0 0111 .24 24.49 24.49 0 019.51 4.22 20.73 20.73 0 016.46 8.22 24.21 24.21 0 011.64 12.45 38.57 38.57 0 01-3.64 13.86 100.42 100.42 0 01-6.93 12.57 112.83 112.83 0 01-6.93 9.63c-2.11 2.58-3.4 4.19-3.87 4.81a7.37 7.37 0 00-.47 1.29 2.56 2.56 0 00.47 2q2.12 3.52 6.1 10t8.22 13.27q4.23 6.83 7.87 12.57t4.82 7.87c.94 1.56 2.44 2.08 4.7 2.35h-35.42a35.59 35.59 0 01-8.1-.82 6.15 6.15 0 01-4.58-3.65q-2.64-5-5.52-10.52l-5.64-10.8c-1.8-3.53-3.52-6.85-5.17-10s-3-5.79-4.11-8a4.47 4.47 0 01-.58-3.17 9.57 9.57 0 012.23-3.41q2.34-3 6-8.34t7.4-11.27q3.75-6 6.81-11.86a51.38 51.38 0 004.22-10.34 23 23 0 00.47-10.21 10.33 10.33 0 00-3.28-6 8.07 8.07 0 00-5.76-1.65 13 13 0 00-6.71 2.63M705.57 130.36v28.18a5.7 5.7 0 001.65 4.23 6.9 6.9 0 004.22 1.65h-48.85c2.2-.27 3.13-.55 4.23-1.65a5.69 5.69 0 001.64-4.23v-104a5.86 5.86 0 00-1.64-4.22c-.43-.44-1.11-1.14-4.23-1.65h48.85a7.18 7.18 0 00-4.22 1.65 5.16 5.16 0 00-1.65 4.22v75.82zM716.68 15.26a15.2 15.2 0 00-7.51-3.61 9.8 9.8 0 00-6.36.91 5.61 5.61 0 00-3 4.4c-.18 1.87-.3 3.63-.37 5.3a17.2 17.2 0 01-5.66 12.41 17.65 17.65 0 01-24.7-1.1 16.55 16.55 0 01-4.42-12.46A16.77 16.77 0 01670.17 9 22.37 22.37 0 01680 3.73a30.41 30.41 0 0111.79-.54 43.33 43.33 0 0112.61 3.94 54.67 54.67 0 0112.28 8.13"
          className="cls-1"
        ></path>
      </g>
    </svg>
  )
}
